export  const getTimeDiff = (time) => {
  const currentTime = new Date().getTime();
  const diffTime = Math.round((currentTime - time*1000)/1000);

  if( diffTime < 60 ){
    return '1 minute ago'
  }
  const diffMin = Math.round(diffTime/60);

  if(diffMin < 60){
    return  `${diffMin} minutes ago`
  }

  const diffHour = Math.round(diffMin/60)

  if(diffHour < 24){
    if(diffHour === 1){
      return  '1 hour ago'
    }
    return  `${diffHour} hours ago`
  }

  const diffDay = Math.round(diffHour/24)

  if(diffDay < 30){
    if(diffDay === 1){
      return  '1 day ago'
    }
    return  `${diffDay} days ago`
  }

  const diffMonth = Math.round(diffDay/30);

  if(diffMonth < 12){
    if(diffMonth === 1){
      return  '1 month ago'
    }
    return `${diffMonth} months ago`
  }

  const diffYear  = Math.round(diffMonth/12)

  if(diffMonth === 1){
    return  '1 year ago'
  }

  return `${diffYear} years ago`;
}
