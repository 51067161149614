import React, { useEffect, useState } from "react"
import { useQueryParam, StringParam } from "use-query-params";
import firebase from "../../src/components/auth/fire-base.js";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import {makeStyles} from "@material-ui/styles"
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from "clsx"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import {getTimeDiff} from "../helpers"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Unknown from "../images/unkown.png"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import logo from '../images/pin-logo.jpg'
import {Divider} from "@material-ui/core"
import LockIcon from '@material-ui/icons/Lock';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import BlockIcon from '@material-ui/icons/Block';
import $ from 'jquery';
const mime = require('mime-types')

const PinComponent = () =>{
  const classes = useStyles();
  const [pinCode, setPinCode] = useQueryParam("pincode", StringParam);
  const [pin, setPin] = React.useState(null)
  const [loading, setLoading] = React.useState(true)
  const [posts, setPosts] = React.useState([]);
  const [popUp, setPopUp] = React.useState(true);
  const [appLink, setAppLink] = React.useState('https://apps.apple.com/us/app/cloudpin/id1516110996');
  const [viewPin, setViewPin]=useState(false);
  const [passwordPopUp, setPasswordPopUp] = useState(true);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [noPin, setNoPin] = useState(false);
  React.useEffect(()=>{
    getMobileOperatingSystem()
    window.location.href = `cloudpin://pin/${pinCode}`;
    setTimeout(()=>{
      if(pinCode){
        firebase.firestore().collection('pins').doc(pinCode).get().then(snapShot=>{
          if(snapShot.data()){
            const result = snapShot.data();
            if(result){
              setPin(result)
              console.log(result)
            }
            let tempPosts = [];
            if(result.posts){
              tempPosts = result.posts;
            }else {
              if(result.content){
                tempPosts.push({
                  comments:[],
                  description: result.content.text,
                  files:[{
                      uri: result.content.file
                    }],
                  postedAt: result.createdAt,
                  poster: result.creator.id,
                })
              }
            }
            setPosts(tempPosts);
            if(result.passwordRequired){
              setViewPin(false);
              setPasswordPopUp(true);
            }else {
              setViewPin(true);
              setPasswordPopUp(false);
            }
            setLoading(false);
          }else {
            setNoPin(true);
          }
        })
      }
    }, 500)

  },[pinCode])

  const getMobileOperatingSystem = () => {
    let  userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setAppLink('https://play.google.com/store/apps/details?id=com.cloudpin.cloudpin');
    }
  }

  if (!loading){
    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <div onClick={()=>{window.location.href='/'}}><KeyboardBackspaceIcon /></div>
          <h5>{pinCode}</h5>
          <MoreVertIcon />
        </div>
        {
          viewPin ?
            <div className={classes.content}>
              {
                posts.map((post, index)=>(
                  <PostItem key={index} post={post} pin={pin} />
                ))
              }
            </div>:
            <div className={classes.content}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column',width:'100%', height:'calc(100vh - 100px)'}}>
                    <LockIcon style={{fontSize: 70, color:'grey'}}/>
                    <div onClick={()=>{setPasswordPopUp(true)}} style={{color:'#e35835', cursor:'pointer',}}>View Pin</div>
                    <Backdrop style={{zIndex: 100000, color:'#FFF'}} open={passwordPopUp}>
                      <Paper style={{padding: 20, textAlign:'center'}}>
                        <strong>View Pin</strong><br/>
                        <span>To view locked pin, enter <br/>the password.</span>
                        <input type='password' className={'form-control '+ (error?'is-invalid':'')} onChange={(e)=>{setPassword(e.target.value)}} />
                        <span className={'text-danger'}>{error}</span>
                        <div style={{marginTop: 20,}}>
                          <button className='btn btn-sm btn-danger' style={{width: 70, marginRight: 20,}} onClick={()=>{setPasswordPopUp(false)}}>Close</button>
                          <button className='btn btn-sm btn-primary'  style={{width: 70}} onClick={()=>{
                              if(password === pin.password){
                                setViewPin(true);
                              }else {
                                setError('incorrect password, try again.');
                              }
                          }}>Ok</button>
                        </div>
                      </Paper>
                    </Backdrop>
                </div>
            </div>
        }
        {
          popUp &&
          <div className={classes.popUpBox}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', paddingLeft: 20, paddingRight: 20,paddingBottom: 10,}}>
              <img src={logo} style={{width: 40, height: 60}} alt='logo' />
              <div style={{display:'flex', alignItems:'flex-start', flexDirection:'column', paddingLeft: 10, }}>
                <strong style={{fontSize: 14}}> Join & share with the Cloudpin App</strong>
                <span style={{fontSize: 14}}>Get notifications whe new content is added</span>
              </div>
            </div>
            <Divider style={{width:'100%'}} />
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding: 10,}}>
              <button className={classes.noThanksButton} onClick={()=>{setPopUp(false)}}>NO THANKS</button>
              <a href={appLink} target={'_blank'} className={classes.getTheAppButton}><span style={{color:'white'}}>GET THE APP</span></a>
            </div>
          </div>
        }
      </div>
    )
  }else {
    return(
      <div className={clsx(classes.root, classes.flexCenter)}>
        {
          noPin?<div style={{display:'flex', flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <BlockIcon style={{color: "red", fontSize: 70}}/>
            <p>Pin does not exist, try again.</p>
            <a href='/'> Go To CloudPin</a>
          </div>:
            <CircularProgress />
        }
      </div>
    )
  }
}

const PostItem = ({ post, pin }) =>{
  const classes = useStyles();
  const [poster, setPoster] = React.useState(null);
  const [password, setPassword] = useQueryParam("password", StringParam);
  const [numberOfCommentsToShow, setNumberOfCommentToShow] = React.useState(2);
  const settings = {
    dots: false,
    infinite:true,
    speed: 500,
    slidesToShow: post.files.length > 1?2:post.files.length,
    arrows: true,
    slidesToScroll:1,
    autoplay: false,
    autoplaySpeed: 5000,
    initialSlide: 0,
    className: "slides",
    rows: 1,
  }

  useEffect(()=>{
    if(post.poster){
      firebase.firestore().collection('users').doc(post.poster).get().then(snapShot=>{
        if(snapShot.data()){
          setPoster(snapShot.data());
        }
      });
    }
  },[pin])

  const RenderFile = ({ file }) =>{
    const mimeType = getMimType(file);
    if(mimeType === 'video'){
      return  <div style={{width: '100%', height: 150,}}>
        <video src={file.uri} style={{width: '100%', height: 150, objectFit: 'cover'}}/>
      </div>
    }else if(mimeType === 'image'){
      return <img src={file.uri} style={{width: '100%', height: 150, border:'solid 1px #8080807f', objectFit: 'cover'}} alt={file.uri}/>
    }else {
      return <img src={Unknown}  style={{width: '100%', height: 150, border:'solid 1px #8080807f',  objectFit: 'cover'}} alt={'Unknown'}/>
    }
  }

  const getFileName = (url) => {
    if(url){
      return url.replace('%2F','/').replace('%20' ,' ').split('/').pop().split('?').shift();
    }else {
      return  'no file';
    }
  }

  const getMimType = ( file ) => {
    if(file.mimeType){
      return file.mimeType
    }else {
      if(file.uri){
        mime.lookup(file.uri).split('/').shift()
      }else {
        return  undefined;
      }
    }
  }
  return (<>
    {
      poster &&
      <div className={classes.userContainer}>
        <Avatar src={poster.displayPic} className={classes.normal}>{poster.username.toUpperCase().slice(0, 1)}</Avatar>
        <div className={classes.userNameWrapper}>
          <div>{poster.username}</div>
          <div>{getTimeDiff(post.postedAt.seconds)}</div>
        </div>
      </div>
    }
    <div style={{ width: "85%" , height: 150, border:'solid 1px #d4d4d47f'}}>
      <Slider {...settings}>
        {
          post.files && post.files.map((file, index)=>(
            <div  key = {index}>
              <RenderFile file={file} />
            </div>
          ))
        }
      </Slider>
    </div>
    <div className={classes.download}>
      <span style={{fontSize: 14, fontWeight:'bold'}}>Download All</span>
      <div>
        <SystemUpdateAltIcon />
      </div>
    </div>
    <div className={classes.comments}>
      {
        poster &&
        <div className={classes.commentItem}>
          <strong style={{paddingRight: 10,}}>{poster.username}</strong>
          <span>{post.description?post.description:'undefined'}</span>
        </div>
      }
      <div style={{color:'#d4d4d4', fontSize: 14, marginTop: 20}}>View All Comments</div>
      {
        post.comments && post.comments.slice(0, numberOfCommentsToShow).map((comment, index)=>(
          <div key={index} className={classes.commentItem}>
            <strong style={{paddingRight: 10,}}>{comment.poster}</strong>
            <span>{comment.comment}</span>
          </div>
        ))
      }
      <div>
        {
          post.comments.length > 2 &&
          <div style={{display:'flex', width: '100%', justifyContent:'flex-end'}}>
            {
              numberOfCommentsToShow  === 2 ?
                <div onClick={()=>{setNumberOfCommentToShow(post.comments.length)}}><span style={{color:'#e7523a', fontWeight:'bold', fontSize: 12, paddingRight: 20}}>view More...</span></div>:
                <div onClick={()=>{setNumberOfCommentToShow(2)}}><span style= {{color:'#e7523a', fontWeight:'bold', fontSize: 12, paddingRight: 20}}>view less...</span></div>
            }
          </div>
        }
      </div>
    </div>
  </>)
}


const useStyles = makeStyles({
  noThanksButton:{
    width: 150,
    height: 40,
    fontSize: 18,
    backgroundColor:'white',
    border:'solid 1px #d4d4d4'
  },
  getTheAppButton:{
    width: 150,
    height: 40,
    fontSize: 18,
    backgroundColor:'#e7523a',
    color:'withe',
    border:'solid 1px #d4d4d4',
    textDecoration:'none',
    textAlign:'center',
    verticalAlign:'center',
    paddingTop: 6,
  },
  popUpBox:{
    position:'fixed',
    width: '100%',
    margin:'auto',
    bottom: 70,
    border:'solid 1px #8080807f',
    height: 150,
    backgroundColor:'white',
    padding: 10,
  },
  postBox:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  },
  commentItem:{
    marginTop: 14,
    display: 'flex',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'flex-start',
  },
  comments:{
    width:'90%',
    padding: '0 10px',
    margin:'auto',
  },
  download:{
    display:'flex',
    borderBottom:'solid 1px #d4d4d47f',
    justifyContent:'space-between',
    padding:'10px 10px',
    width:'90%',
    alignItems:'center'
  },
  userNameWrapper:{
    marginLeft: 10,
  },
  normal:{
    width: 40,
    height: 40
  },
  userContainer:{
    display:'flex',
    alignItems:'center',
    height: 80,
    width:'100%',
    padding: '0 10px'
  },
  content:{
    padding:'0, 20px',
    width:'100%',
    display:'flex',
    alignItems:'center',
    maxWidth: 1024,
    margin:'auto',
    flexDirection:'column'
  },
  header:{
    width: '100vw',
    height: 60,
    boxShadow:'0 0 2px 4px #8080807f',
    display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    padding:'0 30px'
  },
  flexCenter:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
  },
  root:{
    position:'relative',
    width: '100%',
    minHeight:'100vh',
    backgroundColor:'white',
  },
})

export default PinComponent
