import React from "react"
import "bootstrap/dist/css/bootstrap.css"
import PinComponent from "../components/pin-component"
const PinsPage = () => (
  <div>
        <PinComponent></PinComponent>
  </div>
)

export default PinsPage
